import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext/useAuthContext';
import services from '../../services';
import { fetchProducts, setSelectedAccountAction, setUserInfoAction } from '../../store/ducks/inSiteAppState';
import { getExternalOrgAccounts, getUserOrg } from '../../utils';

function PrivateRoute({ children }: any) {
  const { isAuthenticated } = useAuthContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getAccountInfo = async () => {
    try {
      console.log('Account Info Call');
      let response = await services.getAccountInfo();
      // let response = {
      //   _id: '67365151682d825d87c1979d',
      //   working_account: 'acc_6776f6203b124c6e1ec43061',
      //   email: 'in-site@rankworks.com',
      //   name: {
      //     first_name: 'Test',
      //     last_name: 'Insite',
      //   },
      //   phone: null,
      //   address: null,
      //   term_url: '',
      //   permissions: [
      //     {
      //       _id: '678aa3bcf353530a0a541517',
      //       access_to: '677721ca3b124c6e1ec43067',
      //       resources: ['ADS', 'SMP', 'GMB', 'SEO', 'GA4'],
      //       access_expires: 'Never',
      //       status: 'ACTIVATED',
      //       display_name: '',
      //       last_signed_in: '',
      //       event_logs: null,
      //       invited_by: 'insiteck@gmail.com',
      //       date_invited: '1737139132.173298',
      //       account_details: {
      //         _id: '677721ca3b124c6e1ec43067',
      //         is_ecommerce: false,
      //         website_analytics_dashboard: 'paid',
      //         name: 'Affari',
      //         display_name: null,
      //         primary_category: 'Office furniture store',
      //         org_id: '67365c50682d825d87c197a5',
      //         websites: [
      //           {
      //             website_id: '677721cf3b124c6e1ec43068',
      //             website: 'mobilieraffari.com',
      //             gtm_public_id: 'GTM-MP762NTV',
      //             ga4_property_id: '471473407',
      //             is_tagged: true,
      //           },
      //         ],
      //         locations: [
      //           {
      //             location_id: '677721cf3b124c6e1ec43069',
      //             coordinates: {
      //               lat: '45.5000565',
      //               lng: '-73.74716169999999',
      //             },
      //             display_name: '9106-B Boulevard Henri Bourassa Ouest Montreal',
      //             primary_service_area: {
      //               city: 'Montreal',
      //               state: 'QC',
      //               country: {
      //                 _id: '661029cc6d52d5340f507289',
      //                 id: 38,
      //                 sortname: 'CA',
      //                 name: 'Canada',
      //                 phonecode: 1,
      //                 serpstat_country_id: 24,
      //               },
      //             },
      //           },
      //         ],
      //         connections: {
      //           instagram: {
      //             connection_id: 'cb8872f6-5e2f-4437-a6e3-7128db35a2e6',
      //             default_customer_account: {
      //               account_id: '1789893247786090',
      //               name: 'Mobilier Affari',
      //               id: '',
      //               meta_tokens_id: '67772c84ff6875ec22e9002b',
      //             },
      //           },
      //           facebook: {
      //             connection_id: '88de872a-5e40-4c40-8abd-f302883c80ba',
      //             default_customer_account: {
      //               account_id: '1789893247786090',
      //               name: 'Mobilier Affari',
      //               id: '',
      //               meta_tokens_id: '67772c9bff6875ec22e9002c',
      //             },
      //           },
      //           gmb: {
      //             connection_id: '81d54de8-c6e5-401a-b9d6-b8f2bfe00323',
      //             default_customer_account: {
      //               account_id: '114845919986141566656',
      //               account_name: 'AFFARI',
      //               location_id: '7534598447555961414',
      //               location_title:
      //                 'AFFARI - Office furniture and turnkey services / Aménagement de bureaux - services clés en main',
      //             },
      //           },
      //           google_ads: {
      //             connection_id: '0c8e2ce0-be02-4df4-8b78-e72ddff55f63',
      //             default_customer_account: {
      //               customer_account_id: '9254218617',
      //               descriptive_name: 'Affari',
      //               currency_code: 'CAD',
      //               time_zone: 'America/Toronto',
      //               status: {
      //                 index: 2,
      //                 name: 'ENABLED',
      //                 description: 'Indicates an active account able to serve ads.',
      //               },
      //               manager_account_id: '6305345192',
      //             },
      //           },
      //         },
      //       },
      //     },
      //     {
      //       _id: '678aa3bcf353530a0a541516',
      //       access_to: '6776f6203b124c6e1ec43061',
      //       resources: ['ADS', 'SMP', 'GMB', 'SEO', 'GA4'],
      //       access_expires: 'Never',
      //       status: 'ACTIVATED',
      //       display_name: '',
      //       last_signed_in: '',
      //       event_logs: null,
      //       invited_by: 'insiteck@gmail.com',
      //       date_invited: '1737139132.171997',
      //       account_details: {
      //         _id: '6776f6203b124c6e1ec43061',
      //         is_ecommerce: false,
      //         website_analytics_dashboard: 'paid',
      //         name: 'Aqua Spa Nuance',
      //         display_name: null,
      //         primary_category: 'Day spa',
      //         org_id: '67365c50682d825d87c197a5',
      //         websites: [
      //           {
      //             website_id: '6776f6243b124c6e1ec43062',
      //             website: 'aquaspalg.com',
      //             gtm_public_id: 'GTM-T75PH9F9',
      //             ga4_property_id: '471456535',
      //             is_tagged: true,
      //           },
      //         ],
      //         locations: [
      //           {
      //             location_id: '6776f6243b124c6e1ec43063',
      //             coordinates: {
      //               lat: '45.4895636',
      //               lng: '-73.8205567',
      //             },
      //             display_name: ' Dollard-Des Ormeaux',
      //             primary_service_area: {
      //               city: 'Dollard-Des Ormeaux',
      //               state: 'QC',
      //               country: {
      //                 _id: '661029cc6d52d5340f507289',
      //                 id: 38,
      //                 sortname: 'CA',
      //                 name: 'Canada',
      //                 phonecode: 1,
      //                 serpstat_country_id: 24,
      //               },
      //             },
      //           },
      //         ],
      //         connections: {
      //           instagram: {
      //             connection_id: 'cb8872f6-5e2f-4437-a6e3-7128db35a2e6',
      //             default_customer_account: {
      //               account_id: '223957631241',
      //               name: 'Aqua Spa Nuance formerly Lyne Giroux',
      //               id: '',
      //               meta_tokens_id: '6776f6deff6875ec22e9002a',
      //             },
      //           },
      //           facebook: {
      //             connection_id: '88de872a-5e40-4c40-8abd-f302883c80ba',
      //             default_customer_account: {
      //               account_id: '223957631241',
      //               name: 'Aqua Spa Nuance formerly Lyne Giroux',
      //               id: '',
      //               meta_tokens_id: '6776f6ed568be1001026c4f2',
      //             },
      //           },
      //           gmb: {
      //             connection_id: '81d54de8-c6e5-401a-b9d6-b8f2bfe00323',
      //             default_customer_account: {
      //               account_id: '113460263361498796197',
      //               account_name: 'AquaSpa LG',
      //               location_id: '14845553189202222034',
      //               location_title: 'Aqua Spa Nuance (formerly Aqua Spa Lyne Giroux)',
      //             },
      //           },
      //           meta_ads: {
      //             connection_id: '862409a1-d931-4b33-ae75-313006883440',
      //             default_customer_account: {
      //               account_id: '1356274325162690',
      //               name: 'Nuance Medispa',
      //               id: 'act_1356274325162690',
      //             },
      //           },
      //         },
      //       },
      //     },
      //     {
      //       _id: '678aa3bcf353530a0a541515',
      //       access_to: '6776f58b9cff3391caa70b5c',
      //       resources: ['ADS', 'SMP', 'GMB', 'SEO', 'GA4'],
      //       access_expires: 'Never',
      //       status: 'ACTIVATED',
      //       display_name: '',
      //       last_signed_in: '',
      //       event_logs: null,
      //       invited_by: 'insiteck@gmail.com',
      //       date_invited: '1737139132.112997',
      //       account_details: {
      //         _id: '6776f58b9cff3391caa70b5c',
      //         is_ecommerce: false,
      //         website_analytics_dashboard: 'paid',
      //         name: 'Climate Control Systems',
      //         display_name: null,
      //         primary_category: 'HVAC contractor',
      //         org_id: '67365c50682d825d87c197a5',
      //         websites: [
      //           {
      //             website_id: '6776f5909cff3391caa70b5d',
      //             website: 'climatecontrol411.com',
      //             gtm_public_id: 'GTM-KSSFNJZ9',
      //             ga4_property_id: '471460341',
      //             is_tagged: true,
      //           },
      //         ],
      //         locations: [
      //           {
      //             location_id: '6776f5909cff3391caa70b5e',
      //             coordinates: {
      //               lat: '41.4958665',
      //               lng: '-87.84866129999999',
      //             },
      //             display_name: ' Frankfort',
      //             primary_service_area: {
      //               city: 'Frankfort',
      //               state: 'IL',
      //               country: {
      //                 _id: '661029cc6d52d5340f50734a',
      //                 id: 231,
      //                 sortname: 'US',
      //                 name: 'United States',
      //                 phonecode: 1,
      //                 serpstat_country_id: 23,
      //               },
      //             },
      //           },
      //         ],
      //         connections: {
      //           gmb: {
      //             connection_id: '81d54de8-c6e5-401a-b9d6-b8f2bfe00323',
      //             default_customer_account: {
      //               account_id: '102543717408577774587',
      //               account_name: 'Climate Control Systems',
      //               location_id: '14999390772567289084',
      //               location_title: 'Climate Control Systems',
      //             },
      //           },
      //         },
      //       },
      //     },
      //     {
      //       _id: '673651a6682d825d87c1979f',
      //       access_to: '673651a6682d825d87c1979e',
      //       resources: [
      //         'VIEW_CUSTOMER_ACCOUNT_USER',
      //         'EDIT_CUSTOMER_ACCOUNT_USER',
      //         'VIEW_CUSTOMER_ACCOUNT',
      //         'EDIT_CUSTOMER_ACCOUNT',
      //         'VIEW_BILLING',
      //         'EDIT_BILLING',
      //         'INTEGRATION',
      //         'VIEW_MANAGER_ACCOUNT',
      //         'EDIT_MANAGER_ACCOUNT',
      //         'VIEW_MANAGER_ACCOUNT_USER',
      //         'EDIT_MANAGER_ACCOUNT_USER',
      //       ],
      //       access_expires: 'Never',
      //       status: 'ACTIVATED',
      //       display_name: '',
      //       last_signed_in: '',
      //       event_logs: null,
      //       invited_by: 'in-site@rankworks.com',
      //       date_invited: '1731613094.623825',
      //       organization_details: {
      //         _id: '673651a6682d825d87c1979e',
      //         name: 'Testing',
      //         plans: [
      //           {
      //             plan_type: 'plans',
      //             plan_name: 'Starter',
      //             plan_id: 'prod_Py9gC6XCNbmBTn',
      //             plan_credits: {
      //               ai: '10000',
      //               category: 'planss',
      //               dashboard: 'free',
      //               gbpupload: '997',
      //               rec: '0',
      //               reviewai: '0',
      //               seodashboard: 'free',
      //               socialupload: '9989',
      //               stock: '220',
      //             },
      //             created_at: '2025-01-13 15:34:59',
      //           },
      //         ],
      //         primary_acc_id: '677fe0c04ef4bea69ed88764',
      //         max_org_accounts: 2,
      //         max_org_users: 1,
      //         accounts: [
      //           {
      //             _id: '673651a6682d825d87c197a0',
      //             is_ecommerce: false,
      //             name: 'Investiprise',
      //             display_name: null,
      //             primary_category: 'Aboriginal and Torres Strait Islander organisation',
      //             org_id: '673651a6682d825d87c1979e',
      //             websites: [
      //               {
      //                 website_id: '673651ab682d825d87c197a1',
      //                 website: 'https://investiprise.com',
      //                 gtm_public_id: 'GTM-KNBKH9H6',
      //                 ga4_property_id: '422095701',
      //               },
      //             ],
      //             locations: [
      //               {
      //                 location_id: '673651ab682d825d87c197a2',
      //                 coordinates: {
      //                   lat: '45.4490159',
      //                   lng: '-73.6724266',
      //                 },
      //                 display_name: '1100 Rue Norman Montreal',
      //                 primary_service_area: {
      //                   city: 'Montreal',
      //                   state: 'QC',
      //                   country: {
      //                     _id: '661029cc6d52d5340f507289',
      //                     id: 38,
      //                     sortname: 'CA',
      //                     name: 'Canada',
      //                     phonecode: 1,
      //                     serpstat_country_id: 24,
      //                   },
      //                 },
      //               },
      //             ],
      //             connections: {
      //               meta_ads: {
      //                 connection_id: '749047ed-1316-4e0d-9eb4-44a1cdefaf67',
      //                 default_customer_account: {
      //                   account_id: '1054924292628092',
      //                   name: 'Boost',
      //                   id: 'act_1054924292628092',
      //                 },
      //               },
      //               facebook: {
      //                 connection_id: '99fb7013-e81e-4325-9cf0-38a6bc752ca2',
      //                 default_customer_account: {
      //                   account_id: '164059216781710',
      //                   name: 'Test Influencer',
      //                   id: '',
      //                   meta_tokens_id: '67366350440bc263d8b5fcc7',
      //                 },
      //               },
      //               gmb: {
      //                 connection_id: 'b1b71003-442c-42bb-b4b5-6820fdac1349',
      //                 default_customer_account: {
      //                   account_id: '108748119507015502497',
      //                   account_name: 'Apple White Dental Arts',
      //                   location_id: '4780111022893749197',
      //                   location_title: 'Applewhite Dental Arts',
      //                 },
      //               },
      //               google_ads: {
      //                 connection_id: '44bc8e91-9094-4774-a57a-8790535b63cc',
      //                 default_customer_account: {
      //                   customer_account_id: '7433075693',
      //                   descriptive_name: 'Investiprise',
      //                   currency_code: 'CAD',
      //                   time_zone: 'America/Toronto',
      //                   status: {
      //                     index: 2,
      //                     name: 'ENABLED',
      //                     description: 'Indicates an active account able to serve ads.',
      //                   },
      //                   manager_account_id: '7433075693',
      //                 },
      //               },
      //               instagram: {
      //                 connection_id: '98ca028c-f75c-432a-83a9-709449cca2c9',
      //                 default_customer_account: {
      //                   account_id: '107848387709023',
      //                   name: 'Influencer Union',
      //                   id: '',
      //                   meta_tokens_id: '673786d5a340fe7b1ff21959',
      //                 },
      //               },
      //             },
      //             website_analytics_dashboard: 'free',
      //           },
      //           {
      //             _id: '67363d1050002996f59db3e5',
      //             is_ecommerce: false,
      //             name: 'Caroline Bourdages',
      //             display_name: 'Caroline Bourdages',
      //             primary_category: 'Florist',
      //             org_id: '673651a6682d825d87c1979e',
      //             websites: [
      //               {
      //                 website_id: '67363d7050002996f59db3e6',
      //                 website: 'https://serreenville.com',
      //                 gtm_public_id: 'GTM-PNVV8PKS',
      //                 ga4_property_id: '433445479',
      //               },
      //             ],
      //             locations: [
      //               {
      //                 location_id: '67363d7050002996f59db3e7',
      //                 coordinates: {
      //                   lat: 45.403271,
      //                   lng: -71.889038,
      //                 },
      //                 display_name: '',
      //                 primary_service_area: {
      //                   _id: '661029cc6d52d5340f4fcf4d',
      //                   geo: {
      //                     lat: 45.403271,
      //                     lng: -71.889038,
      //                   },
      //                   city: 'Sherbrooke',
      //                   state: 'Quebec',
      //                   country: {
      //                     name: 'Canada',
      //                     phonecode: 1,
      //                     sort_name: 'CA',
      //                     serpstat_country_id: 24,
      //                   },
      //                 },
      //               },
      //             ],
      //             website_analytics_dashboard: 'free',
      //             connections: {
      //               facebook: {
      //                 connection_id: '99fb7013-e81e-4325-9cf0-38a6bc752ca2',
      //                 default_customer_account: {
      //                   account_id: '436794589524536',
      //                   name: 'American Deli',
      //                   id: '',
      //                   meta_tokens_id: '677eaf51da48e694b6ff1962',
      //                 },
      //               },
      //               gmb: {
      //                 connection_id: 'abc88261-6194-4f70-8c1a-b8cbfe7076f6',
      //                 default_customer_account: {
      //                   account_id: '102692470069838443939',
      //                   account_name: '1 Towing',
      //                   location_id: '17525848550221817779',
      //                   location_title: '1 Towing',
      //                 },
      //               },
      //               google_ads: {
      //                 connection_id: 'ff161cc1-5d27-4658-a8b2-c0f7b8680204',
      //                 default_customer_account: {
      //                   customer_account_id: '8796043701',
      //                   descriptive_name: 'New customerAccount created with CustomerService on 20240729 17:57:41',
      //                   currency_code: 'USD',
      //                   time_zone: 'America/Los_Angeles',
      //                   status: {
      //                     index: 5,
      //                     name: 'CLOSED',
      //                     description:
      //                       'Indicates a closed account unable to serve ads. Test account will also have CLOSED status. Status is permanent and may not be reopened.',
      //                   },
      //                   manager_account_id: '5957964344',
      //                 },
      //               },
      //             },
      //           },
      //           {
      //             _id: 't736317050002996f59db3b6',
      //             is_ecommerce: false,
      //             name: 'North Bay Rat and Rodent',
      //             display_name: 'North Bay Rat and Rodent',
      //             primary_category: 'exterminator',
      //             org_id: '673651a6682d825d87c1979e',
      //             websites: [
      //               {
      //                 website_id: '67363d7050002996f59db3b7',
      //                 website: 'https://www.northbayrat.com/',
      //                 gtm_public_id: 'GTM-5WM9DSQL',
      //                 ga4_property_id: '445020656',
      //               },
      //             ],
      //             locations: [
      //               {
      //                 location_id: '67363d7050002996f59db3b8',
      //                 coordinates: {
      //                   lat: 37.9735346,
      //                   lng: -122.5310874,
      //                 },
      //                 display_name: '',
      //                 primary_service_area: {
      //                   _id: '661029cc6d52d5340f504e27',
      //                   geo: {
      //                     lat: 37.9735346,
      //                     lng: -122.5310874,
      //                   },
      //                   city: 'San Rafael',
      //                   state: 'California',
      //                   country: {
      //                     name: 'United States',
      //                     phonecode: 1,
      //                     sort_name: 'US',
      //                     serpstat_country_id: 23,
      //                   },
      //                 },
      //               },
      //             ],
      //             website_analytics_dashboard: 'free',
      //             connections: {
      //               gmb: {
      //                 connection_id: 'abc88261-6194-4f70-8c1a-b8cbfe7076f6',
      //                 default_customer_account: {
      //                   account_id: '111453526810214945639',
      //                   account_name: '76 Gas Station',
      //                   location_id: '13613062272315616853',
      //                   location_title: '76 gas station',
      //                 },
      //               },
      //               facebook: {
      //                 connection_id: '99fb7013-e81e-4325-9cf0-38a6bc752ca2',
      //                 default_customer_account: {
      //                   account_id: '436794589524536',
      //                   name: 'American Deli',
      //                   id: '',
      //                   meta_tokens_id: '677eaf51da48e694b6ff1962',
      //                 },
      //               },
      //               instagram: {
      //                 connection_id: 'a0b8b519-5521-492a-8226-3059e5abc4b0',
      //                 default_customer_account: {
      //                   account_id: '471746959362655',
      //                   name: 'Moonlight Plumbing',
      //                   id: '',
      //                   meta_tokens_id: '677ed06179a551f1af6b6b0d',
      //                 },
      //               },
      //               google_ads: {
      //                 connection_id: 'ff161cc1-5d27-4658-a8b2-c0f7b8680204',
      //                 default_customer_account: {
      //                   customer_account_id: '8651307080',
      //                   descriptive_name: 'New customerAccount created with CustomerService on 20240718 15:55:13',
      //                   currency_code: 'USD',
      //                   time_zone: 'America/Los_Angeles',
      //                   status: {
      //                     index: 5,
      //                     name: 'CLOSED',
      //                     description:
      //                       'Indicates a closed account unable to serve ads. Test account will also have CLOSED status. Status is permanent and may not be reopened.',
      //                   },
      //                   manager_account_id: '5957964344',
      //                 },
      //               },
      //               meta_ads: {
      //                 connection_id: '749047ed-1316-4e0d-9eb4-44a1cdefaf67',
      //                 default_customer_account: {
      //                   account_id: '922150676195428',
      //                   name: 'Investiprise',
      //                   id: 'act_922150676195428',
      //                 },
      //               },
      //             },
      //           },
      //           {
      //             _id: '6744d809e86de8c7b140db2c',
      //             is_ecommerce: true,
      //             website_analytics_dashboard: 'free',
      //             name: 'VV',
      //             display_name: null,
      //             primary_category: 'Aadhar center',
      //             org_id: '673651a6682d825d87c1979e',
      //             websites: [
      //               {
      //                 website_id: '6744d80ee86de8c7b140db2d',
      //                 website: 'https://investiprise.com',
      //                 gtm_public_id: 'GTM-MBXM35QL',
      //                 ga4_property_id: '468348903',
      //               },
      //             ],
      //             locations: [
      //               {
      //                 location_id: '6744d80ee86de8c7b140db2e',
      //                 coordinates: {
      //                   lat: '45.491827',
      //                   lng: '-73.72760439999999',
      //                 },
      //                 display_name: '8275 Route Transcanadienne Montreal',
      //                 primary_service_area: {
      //                   city: 'Montreal',
      //                   state: 'QC',
      //                   country: {
      //                     _id: '661029cc6d52d5340f507289',
      //                     id: 38,
      //                     sortname: 'CA',
      //                     name: 'Canada',
      //                     phonecode: 1,
      //                     serpstat_country_id: 24,
      //                   },
      //                 },
      //               },
      //             ],
      //             connections: {
      //               facebook: {
      //                 connection_id: '99fb7013-e81e-4325-9cf0-38a6bc752ca2',
      //                 default_customer_account: {
      //                   account_id: '164059216781710',
      //                   name: 'Test Influencer',
      //                   id: '',
      //                   meta_tokens_id: '67366350440bc263d8b5fcc7',
      //                 },
      //               },
      //               gmb: {
      //                 connection_id: 'b1b71003-442c-42bb-b4b5-6820fdac1349',
      //                 default_customer_account: {
      //                   account_id: '108748119507015502497',
      //                   account_name: 'Apple White Dental Arts',
      //                   location_id: '4780111022893749197',
      //                   location_title: 'Applewhite Dental Arts',
      //                 },
      //               },
      //             },
      //           },
      //           {
      //             _id: '677fe0c04ef4bea69ed88764',
      //             is_ecommerce: true,
      //             website_analytics_dashboard: 'paid',
      //             name: 'abc inc.',
      //             display_name: null,
      //             primary_category: 'Aerial photographer',
      //             org_id: '673651a6682d825d87c1979e',
      //             websites: [
      //               {
      //                 website_id: '677fe0c44ef4bea69ed88765',
      //                 website: 'abcairplane.com',
      //                 gtm_public_id: 'GTM-MJFFWHSM',
      //                 ga4_property_id: '472235659',
      //               },
      //             ],
      //             locations: [
      //               {
      //                 location_id: '677fe0c44ef4bea69ed88766',
      //                 coordinates: {
      //                   lat: '45.5018869',
      //                   lng: '-73.56739189999999',
      //                 },
      //                 display_name: ' Montreal',
      //                 primary_service_area: {
      //                   city: 'Montreal',
      //                   state: 'QC',
      //                   country: {
      //                     _id: '661029cc6d52d5340f507289',
      //                     id: 38,
      //                     sortname: 'CA',
      //                     name: 'Canada',
      //                     phonecode: 1,
      //                     serpstat_country_id: 24,
      //                   },
      //                 },
      //               },
      //             ],
      //             connections: {
      //               instagram: {
      //                 connection_id: '98ca028c-f75c-432a-83a9-709449cca2c9',
      //                 default_customer_account: {
      //                   account_id: '107848387709023',
      //                   name: 'Influencer Union',
      //                   id: '',
      //                   meta_tokens_id: '673786d5a340fe7b1ff21959',
      //                 },
      //               },
      //               facebook: {
      //                 connection_id: '47d78e57-8360-40d5-beae-f6f49675feee',
      //                 default_customer_account: {
      //                   account_id: '109246102165052',
      //                   name: 'RankWorks',
      //                   id: '',
      //                   meta_tokens_id: '677fe152c1d7d667acc28914',
      //                 },
      //               },
      //               gmb: {
      //                 connection_id: 'abc88261-6194-4f70-8c1a-b8cbfe7076f6',
      //                 default_customer_account: {
      //                   account_id: '102692470069838443939',
      //                   account_name: '1 Towing',
      //                   location_id: '17525848550221817779',
      //                   location_title: '1 Towing',
      //                 },
      //               },
      //             },
      //           },
      //         ],
      //       },
      //     },
      //   ],
      //   avatar_url: 'https://file.rankworks.com/e84798a7-828f-4f27-bbeb-692793be155f.png',
      //   notifications: {
      //     bell_amount: 0,
      //     messages: [
      //       {
      //         name: 'Qian Gao',
      //         email: 'qgao@rankworks.com',
      //         ticket_id: 2904049248.0,
      //         note: 'notes 4',
      //         utc: 1720023729.885976,
      //       },
      //       {
      //         name: 'Qian Gao',
      //         email: 'qgao@rankworks.com',
      //         ticket_id: 2904049248.0,
      //         note: 'tester qian 1',
      //         utc: 1720023751.056428,
      //       },
      //       {
      //         name: 'Qian Gao',
      //         email: 'qgao@rankworks.com',
      //         ticket_id: 2904049248.0,
      //         note: 'note qian 2',
      //         utc: 1720023805.788281,
      //       },
      //     ],
      //   },
      //   plugin: 'google',
      // };
      const userOrg: any = getUserOrg(response?.permissions);
      const externalAccounts: any = getExternalOrgAccounts(response?.permissions);
      const selectedAccountId = response?.working_account.split('_')[1] || '';
      let selectedAccountFilter = [];
      if (selectedAccountId && userOrg && externalAccounts) {
        selectedAccountFilter = userOrg?.organization_details?.accounts.filter(
          (item: any) => item._id === selectedAccountId
        );
        if (selectedAccountFilter.length === 0)
          selectedAccountFilter = externalAccounts.filter(
            (itemEx: any) => itemEx?.account_details._id === selectedAccountId
          );
      }
      let selectedAccount =
        selectedAccountFilter.length === 0
          ? userOrg?.organization_details?.accounts[0] || externalAccounts[0]
          : selectedAccountFilter[0];

      console.log(selectedAccount);
      // FIXME: No check for response. If return error try/cath not catch it.
      dispatch(setUserInfoAction(response));
      dispatch(setSelectedAccountAction(selectedAccount));
      const redirectVar = selectedAccount.hasOwnProperty('resources') && selectedAccount?.resources[0];
      if (redirectVar) {
        if (redirectVar === 'SEO') navigate('/seo');
        if (redirectVar === 'SMP') navigate('/social');
        if (redirectVar === 'GMB') navigate('/gmb');
        if (redirectVar === 'GA4') navigate('/dashboard');
        if (redirectVar === 'ADS') navigate('/advertising');
      }
    } catch (error) {
      console.log(error);
      Promise.reject(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated === true) {
      getAccountInfo();
      //dispatch(fetchProducts());
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return isAuthenticated ? children : <Navigate to="/" />;
}

export default PrivateRoute;

import { classNames } from '../../../utils/class-names';

import cls from './page-header.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const PageHeader = (props: Props) => {
  const { className, children } = props;

  return <h4 className={classNames(cls.header, className)}>{children}</h4>;
};

export { PageHeader };

import { AnyAction, Store } from 'redux';
import SeamlessImmutable from 'seamless-immutable';
import services from '../../services';
import * as _ from 'lodash';

export interface InSiteAdminState {
  customers: any;
  users: any;
  searchQuery: string;
}

export const SET_CUSTOMERS_TYPE = 'SET_CUSTOMERS_TYPE';
export const UPDATE_CUSTOMER_TYPE = 'UPDATE_CUSTOMER_TYPE';
export const SET_USERS_TYPE = 'SET_USERS_TYPE';
export const SET_SEARCH_QUERY_TYPE = 'SET_SEARCH_QUERY_TYPE';

// Actions

/** interface for SET_CUSTOMERS_TYPE action */
export interface SetCustomersActionInterface extends AnyAction {
  customers: any;
  type: typeof SET_CUSTOMERS_TYPE;
}

/** interface for UPDATE_CUSTOMER_TYPE action */
export interface UpdateCustomersActionInterface extends AnyAction {
  customer: any;
  type: typeof UPDATE_CUSTOMER_TYPE;
}

/** interface for SET_USERS_TYPE action */
export interface SetUsersActionInterface extends AnyAction {
  users: any;
  type: typeof SET_USERS_TYPE;
}

/** sets the token info status to redux
 * @param {any} customers - the customers status
 * @returns {SetCustomersActionInterface} - an action to set isUserVerifiedNow info to redux store
 */
export const setCutomersAction = (customers: any): SetCustomersActionInterface => ({
  customers,
  type: SET_CUSTOMERS_TYPE,
});

/** sets the customer info status to redux
 * @param {any} customer - the customer
 * @returns {UpdateCustomersActionInterface} - an action to update a customer info to redux store
 */
export const updateCustomersAction = (customer: any): UpdateCustomersActionInterface => ({
  customer,
  type: UPDATE_CUSTOMER_TYPE,
});

/** sets the token info status to redux
 * @param {any} users - the users status
 * @returns {SetUsersActionInterface} - an action to set users info to redux store
 */
export const setUsersAction = (users: any): SetUsersActionInterface => ({
  users,
  type: SET_USERS_TYPE,
});

export const setSearchQueryAction = (query: string): any => ({
  query,
  type: SET_SEARCH_QUERY_TYPE,
});

/** Create type for token reducer actions */
export type tokenActionTypes =
  | SetCustomersActionInterface
  | SetUsersActionInterface
  | UpdateCustomersActionInterface
  | AnyAction;

/** Create an immutable form state */
export type ImmutableInSiteAdminState = SeamlessImmutable.ImmutableObject<InSiteAdminState>;

/** initial form state */
export const initialState: ImmutableInSiteAdminState = SeamlessImmutable({
  customers: {
    loading: false,
    loaded: false,
    items: [],
    total: 0,
    receivedAt: 0,
  },
  users: {
    loading: false,
    loaded: false,
    items: [],
    receivedAt: 0,
  },
  searchQuery: '',
});

// Reducers

/** the form reducer function */
export default function inSiteAdminReducer(
  state: ImmutableInSiteAdminState = initialState,
  action: tokenActionTypes
): ImmutableInSiteAdminState {
  switch (action.type) {
    case SET_CUSTOMERS_TYPE:
      return SeamlessImmutable({
        ...state,
        customers: (action as any).customers,
      });
    case SET_USERS_TYPE:
      return SeamlessImmutable({
        ...state,
        users: (action as any).users,
      });
    case SET_SEARCH_QUERY_TYPE:
      console.log((action as any).query);
      return SeamlessImmutable({
        ...state,
        searchQuery: (action as any).query,
      });
    case UPDATE_CUSTOMER_TYPE:
      //TODO::
      return state;
    default:
      return state;
  }
}

// async thunk
export const fetchCustomers = (query: string = ''): any => {
  return async (dispatch: any, getState: any) => {
    let response;
    dispatch(
      setCutomersAction({
        loading: true,
        loaded: false,
        items: [],
        total: 0,
        receivedAt: 0,
      })
    );
    try {
      response = await services.getAllCustomer(query);
      const data = {
        loading: false,
        loaded: true,
        items: response,
        total: 0,
        receivedAt: 0,
      };
      dispatch(setCutomersAction(data));
      return Promise.resolve(response);
    } catch (error) {
      dispatch(
        setCutomersAction({
          loading: false,
          loaded: true,
          items: [],
          total: 0,
          receivedAt: 0,
        })
      );
      return Promise.reject(error);
    }
  };
};

export const fetchCustomersByPagination = (query: string = '', offset: any): any => {
  return async (dispatch: any, getState: any) => {
    let response;
    // dispatch(
    //   setCutomersAction({
    //     loading: true,
    //     loaded: false,
    //     items: [],
    //     total: 0,
    //     receivedAt: 0,
    //   })
    // );
    try {
      response = await services.getAllCustomerByPagination(query, offset);
      const data = {
        loading: false,
        loaded: true,
        items: response?.users || [],
        total: response?.metadata?.total || 0,
        receivedAt: 0,
      };
      dispatch(setCutomersAction(data));
      return Promise.resolve(response);
    } catch (error) {
      dispatch(
        setCutomersAction({
          loading: false,
          loaded: true,
          items: [],
          total: 0,
          receivedAt: 0,
        })
      );
      return Promise.reject(error);
    }
  };
};

export const fetchUsers = (): any => {
  return async (dispatch: any, getState: any) => {
    let response;
    dispatch(
      setUsersAction({
        loading: true,
        loaded: false,
        items: [],
        receivedAt: 0,
      })
    );
    try {
      response = await services.getAllUser();
      const data = {
        loading: false,
        loaded: true,
        items: response,
        receivedAt: 0,
      };
      dispatch(setUsersAction(data));
      return Promise.resolve(response);
    } catch (error) {
      dispatch(
        setUsersAction({
          loading: false,
          loaded: true,
          items: [],
          receivedAt: 0,
        })
      );
      return Promise.reject(error);
    }
  };
};

// selectors

export function getCustomersTotal(state: Partial<Store>): number {
  let total = (state as any).inSiteAdminInfo?.customers?.total;
  return total;
}

/** get the getUsers info object from store
 * @param {Partial<Store>} state - the redux store
 * @return {any} the users array
 */
export function getCustomers(state: Partial<Store>, sortKeywords?: any): any {
  let data =
    (state as any).inSiteAdminInfo?.customers?.items.asMutable({
      deep: true,
    }) || [];
  let sortedData = [];
  switch (sortKeywords?.key) {
    case 'cie_name':
      sortedData = _.orderBy(data, (item: any) => item.company.name, [
        sortKeywords.asc.cie_name === true ? 'asc' : 'desc',
      ]);
      return sortedData;
    case 'client_name':
      sortedData = _.orderBy(data, (item: any) => item.name.first_name, [
        sortKeywords.asc.client_name === true ? 'asc' : 'desc',
      ]);
      return sortedData;
    case 'client_email':
      sortedData = _.orderBy(data, (item: any) => item.email, [
        sortKeywords.asc.client_email === true ? 'asc' : 'desc',
      ]);
      return sortedData;
    default:
      sortedData = data;
      return sortedData;
  }
}

/** get the single customer info object from store
 * @param {Partial<Store>} state - the redux store
 * @return {any} the customer object
 */
export function getCustomerById(state: Partial<Store>, id: any): any {
  let customers =
    (state as any).inSiteAdminInfo?.customers?.items.asMutable({
      deep: true,
    }) || [];
  let res = _.filter(customers, ['email', id]);
  return res[0] || null;
}

/** get the getUsers info object from store
 * @param {Partial<Store>} state - the redux store
 * @return {any} the users array
 */
export function getUsers(state: Partial<Store>, sortKeywords?: any): any {
  let data =
    (state as any).inSiteAdminInfo?.users?.items.asMutable({
      deep: true,
    }) || [];
  let sortedData = [];
  switch (sortKeywords?.key) {
    case 'first_name':
      sortedData = _.orderBy(data, (item: any) => item.name.first_name, [
        sortKeywords.asc.first_name === true ? 'asc' : 'desc',
      ]);
      return sortedData;
    case 'last_name':
      sortedData = _.orderBy(data, (item: any) => item.name.last_name, [
        sortKeywords.asc.last_name === true ? 'asc' : 'desc',
      ]);
      return sortedData;
    case 'position':
      sortedData = _.orderBy(data, (item: any) => item.position, [sortKeywords.asc.position === true ? 'asc' : 'desc']);
      return sortedData;
    case 'role':
      sortedData = _.orderBy(data, (item: any) => item.role, [sortKeywords.asc.role === true ? 'asc' : 'desc']);
      return sortedData;
    default:
      sortedData = data;
      return sortedData;
  }
}

export function getUserById(state: Partial<Store>, id: any): any {
  let users =
    (state as any).inSiteAdminInfo?.users?.items.asMutable({
      deep: true,
    }) || [];
  let res = _.filter(users, ['_id', id]);
  return res[0] || null;
}

export function getSearchQuery(state: Partial<Store>): any {
  return (state as any).inSiteAdminInfo?.searchQuery || '';
}

import { GraphWrapper } from '../graph-wrapper/graph-wrapper';
import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import { useSelector } from 'react-redux';
import { getCompetitorList } from '../../../../store/ducks/dashboardState';
import { getSelectedAccount, getUserInfo } from '../../../../store/ducks/inSiteAppState';
import { useEffect, useState } from 'react';

const CardGA4GoogleRanking = (props: GraphProps) => {
  const { className, ...rest } = props;
  const [total, setTotal] = useState('-');
  // const userInfo = useSelector(getUserInfo);
  const competitorList = useSelector(getCompetitorList);
  const selectedAccount: any = useSelector(getSelectedAccount);

  const getCompanyRanking = () => {
    if (competitorList.length !== 0 && selectedAccount?.name) {
      competitorList.forEach((element: any, index: number) => {
        if (selectedAccount?.name?.toLowerCase() === element.title.toLowerCase()) {
          setTotal((index + 1).toString());
        }
      });
    }
  };

  useEffect(() => {
    if (total == '-') getCompanyRanking();
  }, [competitorList]);

  return (
    <GraphWrapper className={className} {...rest} header="Google Ranking" total={total}>
      <TextBlock className={cls.info} centred></TextBlock>
    </GraphWrapper>
  );
};

export { CardGA4GoogleRanking };

import { io } from 'socket.io-client';

let socket;
const URL = 'wss://m.rankworks.com';

export const getSocket = () => {
  if (!socket) {
    socket = io(URL); // Replace with your server URL
  }
  return socket;
};

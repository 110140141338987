import cls from './OrgAdminIntegrations.module.scss';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { ContentWrapper } from '../../../components/content-wrapper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Section } from '../../get-help/ui/components/section';
import { PageHeader } from '../../../components/page-header';
import { HR } from '../../../components/hr';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../store/ducks/inSiteAppState';
import socialIcon from '../../integration-page/assets/facebook.png';
import gmbIcon from '../../integration-page/assets/gmb.png';
import gAdsIcon from '../../integration-page/assets/google-ads.png';
import igIcon from '../../integration-page/assets/instagram.png';
import metaAdsIcon from '../../integration-page/assets/fb-ads.webp';
import services from '../../../services';
import { getUserOrg } from '../../../utils';
import { LoginSocialFacebook, IResolveParams } from 'reactjs-social-login';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function OrgAdminIntegrations(props: any) {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const [connections, setConnections] = React.useState<any>([
    {
      connection_id: '749047ed-1316-4e0d-9eb4-44a1cdefaf67',
      connection_type: 'meta_ads',
      oauth: {
        email: 'alipu@rankworks.com',
        first_name: 'Ash',
        last_name: 'Lipu',
      },
      linked_accounts: [
        {
          _id: '673651a6682d825d87c197a0',
          name: 'Investiprise',
          display_name: null,
          website: {
            website_id: '673651ab682d825d87c197a1',
            website: 'https://investiprise.com',
            gtm_public_id: 'GTM-KNBKH9H6',
            ga4_property_id: '422095701',
          },
          location: {
            location_id: '673651ab682d825d87c197a2',
            coordinates: {
              lat: '45.4490159',
              lng: '-73.6724266',
            },
            display_name: '1100 Rue Norman Montreal',
            primary_service_area: {
              city: 'Montreal',
              state: 'QC',
              country: {
                _id: '661029cc6d52d5340f507289',
                id: 38,
                sortname: 'CA',
                name: 'Canada',
                phonecode: 1,
                serpstat_country_id: 24,
              },
            },
          },
        },
      ],
      is_valid: true,
    },
    {
      connection_id: 'cae562c2-d900-4b12-803c-6d2ca54dbdf0',
      connection_type: 'instagram',
      oauth: {
        email: 'alipu@rankworks.com',
        first_name: 'Ash',
        last_name: 'Lipu',
      },
      linked_accounts: [],
      is_valid: true,
    },
    {
      connection_id: '98ca028c-f75c-432a-83a9-709449cca2c9',
      connection_type: 'instagram',
      oauth: {
        email: 'dalper@rankworks.net',
        first_name: 'William',
        last_name: 'Kidd',
      },
      linked_accounts: [
        {
          _id: '673651a6682d825d87c197a0',
          name: 'Investiprise',
          display_name: null,
          website: {
            website_id: '673651ab682d825d87c197a1',
            website: 'https://investiprise.com',
            gtm_public_id: 'GTM-KNBKH9H6',
            ga4_property_id: '422095701',
          },
          location: {
            location_id: '673651ab682d825d87c197a2',
            coordinates: {
              lat: '45.4490159',
              lng: '-73.6724266',
            },
            display_name: '1100 Rue Norman Montreal',
            primary_service_area: {
              city: 'Montreal',
              state: 'QC',
              country: {
                _id: '661029cc6d52d5340f507289',
                id: 38,
                sortname: 'CA',
                name: 'Canada',
                phonecode: 1,
                serpstat_country_id: 24,
              },
            },
          },
        },
      ],
      is_valid: true,
    },
    {
      connection_id: 'b00eee8f-11be-4aeb-aa50-bd4c30837f7d',
      connection_type: 'gmb',
      oauth: {
        email: 'in-site@rankworks.com',
        first_name: 'Test',
        last_name: 'Insite',
      },
      linked_accounts: [
        {
          _id: '673651a6682d825d87c197a0',
          name: 'Investiprise',
          display_name: null,
          website: {
            website_id: '673651ab682d825d87c197a1',
            website: 'https://investiprise.com',
            gtm_public_id: 'GTM-KNBKH9H6',
            ga4_property_id: '422095701',
          },
          location: {
            location_id: '673651ab682d825d87c197a2',
            coordinates: {
              lat: '45.4490159',
              lng: '-73.6724266',
            },
            display_name: '1100 Rue Norman Montreal',
            primary_service_area: {
              city: 'Montreal',
              state: 'QC',
              country: {
                _id: '661029cc6d52d5340f507289',
                id: 38,
                sortname: 'CA',
                name: 'Canada',
                phonecode: 1,
                serpstat_country_id: 24,
              },
            },
          },
        },
      ],
      is_valid: true,
    },
    {
      connection_id: 'ff161cc1-5d27-4658-a8b2-c0f7b8680204',
      connection_type: 'google_ads',
      oauth: {
        email: 'qgao@rankworks.com',
        first_name: 'Qian',
        last_name: 'Gao',
      },
      linked_accounts: [],
      is_valid: false,
    },
    {
      connection_id: '44bc8e91-9094-4774-a57a-8790535b63cc',
      connection_type: 'google_ads',
      oauth: {
        email: 'in-site@rankworks.com',
        first_name: 'Test',
        last_name: 'Insite',
      },
      linked_accounts: [
        {
          _id: '673651a6682d825d87c197a0',
          name: 'Investiprise',
          display_name: null,
          website: {
            website_id: '673651ab682d825d87c197a1',
            website: 'https://investiprise.com',
            gtm_public_id: 'GTM-KNBKH9H6',
            ga4_property_id: '422095701',
          },
          location: {
            location_id: '673651ab682d825d87c197a2',
            coordinates: {
              lat: '45.4490159',
              lng: '-73.6724266',
            },
            display_name: '1100 Rue Norman Montreal',
            primary_service_area: {
              city: 'Montreal',
              state: 'QC',
              country: {
                _id: '661029cc6d52d5340f507289',
                id: 38,
                sortname: 'CA',
                name: 'Canada',
                phonecode: 1,
                serpstat_country_id: 24,
              },
            },
          },
        },
      ],
      is_valid: true,
    },
    {
      connection_id: '99fb7013-e81e-4325-9cf0-38a6bc752ca2',
      connection_type: 'facebook',
      oauth: {
        email: 'alipu@rankworks.com',
        first_name: 'Ash',
        last_name: 'Lipu',
      },
      linked_accounts: [
        {
          _id: '673651a6682d825d87c197a0',
          name: 'Investiprise',
          display_name: null,
          website: {
            website_id: '673651ab682d825d87c197a1',
            website: 'https://investiprise.com',
            gtm_public_id: 'GTM-KNBKH9H6',
            ga4_property_id: '422095701',
          },
          location: {
            location_id: '673651ab682d825d87c197a2',
            coordinates: {
              lat: '45.4490159',
              lng: '-73.6724266',
            },
            display_name: '1100 Rue Norman Montreal',
            primary_service_area: {
              city: 'Montreal',
              state: 'QC',
              country: {
                _id: '661029cc6d52d5340f507289',
                id: 38,
                sortname: 'CA',
                name: 'Canada',
                phonecode: 1,
                serpstat_country_id: 24,
              },
            },
          },
        },
        {
          _id: '6744d809e86de8c7b140db2c',
          name: 'VV',
          display_name: null,
          website: {
            website_id: '6744d80ee86de8c7b140db2d',
            website: 'https://investiprise.com',
            gtm_public_id: 'GTM-MBXM35QL',
            ga4_property_id: '468348903',
          },
          location: {
            location_id: '6744d80ee86de8c7b140db2e',
            coordinates: {
              lat: '45.491827',
              lng: '-73.72760439999999',
            },
            display_name: '8275 Route Transcanadienne Montreal',
            primary_service_area: {
              city: 'Montreal',
              state: 'QC',
              country: {
                _id: '661029cc6d52d5340f507289',
                id: 38,
                sortname: 'CA',
                name: 'Canada',
                phonecode: 1,
                serpstat_country_id: 24,
              },
            },
          },
        },
      ],
      is_valid: true,
    },
    {
      connection_id: '612a4001-9a2b-4ed1-977b-56ddf69f27c3',
      connection_type: 'facebook',
      oauth: {
        email: 'boyle715@gmail.com',
        first_name: 'Tammy',
        last_name: 'Lynn',
      },
      linked_accounts: [],
      is_valid: true,
    },
    {
      connection_id: '47d78e57-8360-40d5-beae-f6f49675feee',
      connection_type: 'facebook',
      oauth: {
        email: 'chriskx@live.ca',
        first_name: 'Chris',
        last_name: 'Keirl',
      },
      linked_accounts: [],
      is_valid: true,
    },
  ]);
  const GOOGL_ADS_CONNECTION_LINK =
    process.env.REACT_APP_ENV_GOOGLE_ADS_CONNECTION_SERVER ||
    'https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=305948266678-offh2ca69usqe2nj1pndahdloipe3dpl.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fapi.sso.rankworks.com%2Fgoogle_ads_callback&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords';
  const GOOGLE_CONNECTION_LINK = process.env.REACT_APP_ENV_GMB_CONNECTION_SERVER || '';
  const userInfo = useSelector(getUserInfo);
  const userOrg: any = getUserOrg(userInfo?.permissions);
  const [fbData, setFbData] = React.useState<any>(null);
  const [metaData, setMetaData] = React.useState<any>(null);
  const [instagramData, setInstagramData] = React.useState<any>(null);

  const handleGAdsConnectClick = React.useCallback(() => {
    if (!userInfo?._id) {
      console.error('User ID is not defined');
      return;
    }
    const userId = userInfo._id;
    const state = userId + '_Y';
    const url = GOOGL_ADS_CONNECTION_LINK + `&state=${state}&prompt=consent&access_type=offline`;
    window.location.href = url;
  }, [userInfo]);

  const handleGMBConnectClick = React.useCallback(async () => {
    if (!userInfo?._id) {
      console.error('User ID is not defined');
      return;
    }

    const userId = userInfo?._id;
    const state = userId + '_Y';
    const url = GOOGLE_CONNECTION_LINK + `&state=${state}&prompt=consent&access_type=offline`;
    window.location.href = url;
  }, [userInfo]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getConnectionData = async () => {
    try {
      const data = {
        org_id: 'org_' + userOrg?.organization_details?._id,
      };
      const res = await services.getAllConnectionTokens(data);
      setConnections(res);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (userOrg) getConnectionData();
  }, [userOrg]);

  const generateFacebookPageAccessToken = async (fbId: string, shortLivedAccessToken: string) => {
    try {
      await services.generateMetaAdsAccessToken(fbId, shortLivedAccessToken);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const generateMetaPageAccessToken = async (fbId: string, shortLivedAccessToken: string) => {
    try {
      await services.generateFacebookAccessToken(fbId, shortLivedAccessToken);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const generateIGPageAccessToken = async (fbId: string, shortLivedAccessToken: string) => {
    try {
      await services.generateInstagramAccessToken(fbId, shortLivedAccessToken);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const revokeConnection = async (event: any) => {
    try {
      const connId = event.target.id;
      const orgId = 'org_' + userOrg?.organization_details?._id;
      if (connId) {
        const data = {
          connection_id: connId,
          org_id: orgId,
        };
        await services.revokeConnectionById(data);
        await getConnectionData();
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const responseFacebook = (response: any) => {
    if (response.accessToken) {
      setFbData(response);
    } else {
      console.log('Login failed');
    }
  };

  const responseInstagram = (response: any) => {
    if (response.accessToken) {
      setInstagramData(response);
    } else {
      console.log('Login failed');
    }
  };

  const responseFb = (response: any) => {
    if (response.accessToken) {
      setMetaData(response);
    } else {
      console.log('Login failed');
    }
  };

  React.useEffect(() => {
    if (fbData) generateFacebookPageAccessToken(fbData?.id, fbData?.accessToken);
  }, [fbData]);

  React.useEffect(() => {
    if (metaData) generateMetaPageAccessToken(metaData?.id, metaData?.accessToken);
  }, [metaData]);

  React.useEffect(() => {
    if (instagramData) generateIGPageAccessToken(instagramData?.id, instagramData?.accessToken);
  }, [instagramData]);

  return (
    <ContentWrapper>
      <PageHeader>Configure Connections</PageHeader>
      <HR />
      <Section>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <img style={{ marginLeft: '15px' }} src={metaAdsIcon} alt="social icon" width="22" height="22" />
            <Typography style={{ fontSize: '16px', marginLeft: '13px' }}>Meta Ads Connections</Typography>
          </AccordionSummary>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <AccordionDetails>
              <Typography style={{ fontSize: '14px' }}>
                Connect your Meta Ads account to get a better view of your web site activity
              </Typography>
            </AccordionDetails>
            <LoginSocialFacebook
              appId="322419423556703"
              scope="email,ads_read"
              onResolve={({ provider, data }: IResolveParams) => {
                responseFacebook(data);
              }}
              onReject={(err) => {
                console.log(err);
              }}
            >
              <Button variant="contained" color="success" style={{ marginRight: '20px' }} startIcon={<AddIcon />}>
                Add connection
              </Button>
            </LoginSocialFacebook>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Connection Id</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Connection Email
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Connection Status
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {connections &&
                  connections
                    .filter((item: any) => item.connection_type === 'meta_ads')
                    .map((row: any) => (
                      <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {row?.connection_id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.oauth?.first_name + ' ' + row?.oauth?.last_name}
                        </TableCell>
                        <TableCell align="right">{row?.oauth?.email}</TableCell>
                        <TableCell align="right">{row?.is_valid}</TableCell>
                        <TableCell align="right">
                          <Button variant="contained" style={{ height: '20px', fontSize: '12px', marginRight: '20px' }}>
                            Linked Accounts
                          </Button>
                          <Button
                            onClick={(event) => revokeConnection(event)}
                            id={row?.connection_id}
                            variant="contained"
                            color="error"
                            style={{ height: '20px', fontSize: '12px' }}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {connections && connections.filter((item: any) => item.connection_type === 'meta_ads').length === 0 && (
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}>0 Result found</div>
              </div>
            )}
          </TableContainer>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <img style={{ marginLeft: '15px' }} src={gAdsIcon} alt="social icon" width="22" height="22" />
            <Typography style={{ fontSize: '16px', marginLeft: '13px' }}>Google Ads Connections</Typography>
          </AccordionSummary>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <AccordionDetails>
              <Typography style={{ fontSize: '14px' }}>
                Drive engagement, reach a wider audience, and cultivate a visually appealing brand image.
              </Typography>
            </AccordionDetails>
            <Button
              variant="contained"
              color="success"
              style={{ marginRight: '20px' }}
              onClick={handleGAdsConnectClick}
              startIcon={<AddIcon />}
            >
              Connect to Google Ads
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Connection Id</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Connection Email
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Connection Status
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {connections &&
                  connections
                    .filter((item: any) => item.connection_type === 'google_ads')
                    .map((row: any) => (
                      <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {row?.connection_id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.oauth?.first_name + ' ' + row?.oauth?.last_name}
                        </TableCell>
                        <TableCell align="right">{row?.oauth?.email}</TableCell>
                        <TableCell align="right">{row?.is_valid}</TableCell>
                        <TableCell align="right">
                          <Button variant="contained" style={{ height: '20px', fontSize: '12px', marginRight: '20px' }}>
                            Linked Accounts
                          </Button>
                          <Button
                            onClick={(event) => revokeConnection(event)}
                            id={row?.connection_id}
                            variant="contained"
                            color="error"
                            style={{ height: '20px', fontSize: '12px' }}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {connections && connections.filter((item: any) => item.connection_type === 'google_ads').length === 0 && (
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}>0 Result found</div>
              </div>
            )}
          </TableContainer>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <img style={{ marginLeft: '15px' }} src={gmbIcon} alt="social icon" width="22" height="22" />
            <Typography style={{ fontSize: '16px', marginLeft: '13px' }}>Google Business Connections</Typography>
          </AccordionSummary>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <AccordionDetails>
              <Typography style={{ fontSize: '14px' }}>
                Connect your Google Business account to get a better view of your web site activity
              </Typography>
            </AccordionDetails>
            <Button
              variant="contained"
              color="success"
              style={{ marginRight: '20px' }}
              onClick={handleGMBConnectClick}
              startIcon={<AddIcon />}
            >
              Connect to Google
            </Button>
          </div>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Connection Id</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Connection Email
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Connection Status
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {connections &&
                  connections
                    .filter((item: any) => item.connection_type === 'gmb')
                    .map((row: any) => (
                      <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {row?.connection_id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.oauth?.first_name + ' ' + row?.oauth?.last_name}
                        </TableCell>
                        <TableCell align="right">{row?.oauth?.email}</TableCell>
                        <TableCell align="right">{row?.is_valid}</TableCell>
                        <TableCell align="right">
                          <Button variant="contained" style={{ height: '20px', fontSize: '12px', marginRight: '20px' }}>
                            Linked Accounts
                          </Button>
                          <Button
                            onClick={(event) => revokeConnection(event)}
                            id={row?.connection_id}
                            variant="contained"
                            color="error"
                            style={{ height: '20px', fontSize: '12px' }}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {connections && connections.filter((item: any) => item.connection_type === 'gmb').length === 0 && (
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}>0 Result found</div>
              </div>
            )}
          </TableContainer>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <img style={{ marginLeft: '15px' }} src={socialIcon} alt="social icon" width="22" height="22" />
            <Typography style={{ fontSize: '16px', marginLeft: '13px' }}>Facebook Connections</Typography>
          </AccordionSummary>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <AccordionDetails>
              <Typography style={{ fontSize: '14px' }}>
                Connect your Facebook account to get a better view of your web site activity
              </Typography>
            </AccordionDetails>

            <LoginSocialFacebook
              appId="322419423556703"
              scope="email, read_insights, pages_show_list, pages_manage_posts, pages_read_engagement"
              onResolve={({ provider, data }: IResolveParams) => {
                responseFb(data);
              }}
              onReject={(err) => {
                console.log(err);
              }}
            >
              <Button variant="contained" color="success" style={{ marginRight: '20px' }} startIcon={<AddIcon />}>
                Connect to Facebook
              </Button>
            </LoginSocialFacebook>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Connection Id</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Connection Email
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Connection Status
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {connections &&
                  connections
                    .filter((item: any) => item.connection_type === 'facebook')
                    .map((row: any) => (
                      <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {row?.connection_id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.oauth?.first_name + ' ' + row?.oauth?.last_name}
                        </TableCell>
                        <TableCell align="right">{row?.oauth?.email}</TableCell>
                        <TableCell align="right">{row?.is_valid}</TableCell>
                        <TableCell align="right">
                          <Button variant="contained" style={{ height: '20px', fontSize: '12px', marginRight: '20px' }}>
                            Linked Accounts
                          </Button>
                          <Button
                            onClick={(event) => revokeConnection(event)}
                            id={row?.connection_id}
                            variant="contained"
                            color="error"
                            style={{ height: '20px', fontSize: '12px' }}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {connections && connections.filter((item: any) => item.connection_type === 'gmb').length === 0 && (
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}>0 Result found</div>
              </div>
            )}
          </TableContainer>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <img style={{ marginLeft: '15px' }} src={igIcon} alt="social icon" width="22" height="22" />
            <Typography style={{ fontSize: '16px', marginLeft: '13px' }}>Instagram Connections</Typography>
          </AccordionSummary>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <AccordionDetails>
              <Typography style={{ fontSize: '14px' }}>
                Connect your Instagram account which is Linked to a facebook page to get a better view of your web site
                activity
              </Typography>
            </AccordionDetails>

            <LoginSocialFacebook
              scope="email, pages_show_list, instagram_basic, instagram_manage_insights, instagram_content_publish"
              appId={'322419423556703'}
              onResolve={({ provider, data }: IResolveParams) => {
                responseInstagram(data);
              }}
              onReject={(err) => {
                console.log(err);
              }}
            >
              <Button variant="contained" color="success" style={{ marginRight: '20px' }} startIcon={<AddIcon />}>
                Connect to Instagram
              </Button>
            </LoginSocialFacebook>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Connection Id</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Connection Email
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Connection Status
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {connections &&
                  connections
                    .filter((item: any) => item.connection_type === 'instagram')
                    .map((row: any) => (
                      <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {row?.connection_id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.oauth?.first_name + ' ' + row?.oauth?.last_name}
                        </TableCell>
                        <TableCell align="right">{row?.oauth?.email}</TableCell>
                        <TableCell align="right">{row?.is_valid}</TableCell>
                        <TableCell align="right">
                          <Button variant="contained" style={{ height: '20px', fontSize: '12px', marginRight: '20px' }}>
                            Linked Accounts
                          </Button>
                          <Button
                            onClick={(event) => revokeConnection(event)}
                            id={row?.connection_id}
                            variant="contained"
                            color="error"
                            style={{ height: '20px', fontSize: '12px' }}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {connections && connections.filter((item: any) => item.connection_type === 'instagram').length === 0 && (
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}>0 Result found</div>
              </div>
            )}
          </TableContainer>
        </Accordion>
      </Section>
    </ContentWrapper>
  );
}

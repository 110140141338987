import React, { memo, useState } from 'react';
import cls from './website-analytics-splash-screen.module.scss';
import { TextBlock } from '../../text-block';
import { Button } from '../../button';
import { Navigate, useNavigate } from 'react-router-dom';
import { getTagManagerScript } from '../../../utils';
import { ReactComponent as IcnCopy } from '../../../assets/icons/icn-copy.svg';
import InSiteSnackBar from '../../InSIteSnackBar/InSiteSnackBar';
import { useSelector } from 'react-redux';
import { getSelectedAccount } from '../../../store/ducks/inSiteAppState';
import { Frame } from '../../frame';
import { Modal } from '../../modal';

export const SplashScreenNoData = memo((props: any) => {
  const selectedAccount: any = useSelector(getSelectedAccount);
  const intro = `Your code has been successfully installed. Data will appear as users visit your website.`;

  return (
    <div className={selectedAccount?.is_ecommerce ? cls.wrapper : cls.wrapperService}>
      <div className={cls.info}>
        <Modal sizeCustom noOverlay setOpen={() => {}} closeButton={false}>
          <Frame>
            <TextBlock header="Wating for traffic..."></TextBlock>
            <TextBlock className={cls.codeBlock}>{intro}</TextBlock>
          </Frame>
        </Modal>
      </div>
    </div>
  );
});

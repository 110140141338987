import _ from 'lodash';
import moment from 'moment';
import socialIcon from '../pages/integration-page/assets/facebook.png';
import igIcon from '../pages/integration-page/assets/instagram.png';
import googleIcon from '../pages/integration-page/assets/google-ads.png';

let CryptoJS = require('crypto-js');
const humanizeDuration = require('humanize-duration');

export const getAxiosConfig = (baseUrl: string, url: string, method: string, headers: any, data: any) => {
  let config = {
    url: url,
    baseURL: baseUrl,
    method: method,
    headers: headers,
    withCredentials: true,
    auth: {
      username: 'dev',
      password: 'rankman',
    },
    data: data,
  };

  return config;
};

export const encryptCallBackSecrets = (word: any) => {
  let key = process.env.REACT_APP_DATA_SECRET_KEY;
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz0123456789';
  let lenIV = 15;
  let ivStr = '';
  for (let i = 0; i < lenIV; i++) {
    let rnum = Math.floor(Math.random() * characters.length);
    ivStr += characters.substring(rnum, rnum + 1);
  }
  let iv = CryptoJS.enc.Base64.parse(ivStr + '=');
  let encrypted = CryptoJS.AES.encrypt(word, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  return '{"st":"' + encrypted.toString() + '","ds":"' + iv.toString() + '"}';
};

export const decryptRoleSecrets = (enc: any, iv: any) => {
  iv = CryptoJS.enc.Base64.parse(iv);
  let key = process.env.REACT_APP_DATA_SECRET_KEY;
  key = CryptoJS.enc.Utf8.parse(key);
  enc = enc.toString();
  let decryptedRole = CryptoJS.AES.decrypt(enc, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  decryptedRole = decryptedRole.toString(CryptoJS.enc.Utf8);
  return decryptedRole;
};

export const getStripePublicKey = (enc: any) => {
  let key = process.env.REACT_APP_STRIPE_KEY || '';
  key = CryptoJS.enc.Utf8.parse(key);
  let iv = process.env.REACT_APP_STRIPE_IV || '';
  iv = CryptoJS.enc.Base64.parse(iv);
  enc = enc.toString();
  let decryptedKey = CryptoJS.AES.decrypt(enc, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  decryptedKey = decryptedKey.toString(CryptoJS.enc.Utf8);
  return decryptedKey;
};

export const getProcessedPhoneNo = (phoneNo: string) => {
  return '(' + phoneNo.substring(0, 3) + ')' + phoneNo.substring(3, 6) + '-' + phoneNo.substring(6, 10);
};

export const getDaysBetweenDates = () => {
  let startDate = moment(moment().subtract(30, 'days'), 'DD-MM-YYYY');
  let endDate = moment(moment(), 'DD-MM-YYYY');
  let now = startDate.clone(),
    dates = [];
  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('DD-MM-YYYY'));
    now.add(1, 'days');
  }
  return dates;
};

export const accumulator = async (data: any, subKey: string, accumulateKey: string) => {
  let helper: any = {};
  return data.reduce((accumulator: any, currentValue: any) => {
    let key = currentValue.day + '-' + currentValue.month + '-' + currentValue.year + '-' + currentValue[subKey];
    if (!helper[key]) {
      helper[key] = Object.assign(
        {},
        {
          [accumulateKey]: parseInt(currentValue[accumulateKey]),
          [subKey]: currentValue[subKey],
          date: currentValue.day + '-' + currentValue.month + '-' + currentValue.year,
        }
      ); // create a copy of currentValue
      accumulator.push(helper[key]);
    } else {
      helper[key][accumulateKey] += parseInt(currentValue[accumulateKey]);
    }
    return accumulator;
  }, []);
};

export const generateLineChartData = (result: any, subKey: string, accumulateKey: string, dataSetKey: string) => {
  let filteredQuery = _.filter(result, {
    [subKey]: dataSetKey,
  });

  let dateList = getDaysBetweenDates();

  dateList.forEach((date: string) => {
    let dt = _.filter(filteredQuery, ['date', date]);
    if (dt.length === 0)
      filteredQuery.push({
        [accumulateKey]: 0,
        [subKey]: dataSetKey,
        date: date,
      });
  });

  let res = _.orderBy(filteredQuery, (item: any) => moment(item.date, 'DD-MM-YYYY'), ['asc']).slice(0, 30);

  return res;
};

export const daysCount = () => {
  const now = new Date();
  const prevMonthDays = new Date(now.getFullYear(), now.getMonth(), 0).getDate();
  const currMonthDays = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();

  return currMonthDays > prevMonthDays ? currMonthDays : prevMonthDays;
};

export const kFormatter = (num: number) => {
  return Math.abs(num) > 999
    ? (Math.sign(num) * (Math.abs(num) / 1000)).toFixed(1) + 'K'
    : Math.sign(num) * Math.abs(num);
};

export const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
});

export const processPrice = (price: string) => {
  let priceNumber = price.slice(0, price.length - 2);
  let priceDecimal = price.slice(-2);
  let res = priceDecimal === '00' ? priceNumber : priceNumber + '.' + priceDecimal;
  return '$' + res;
};

const getOrCreateTooltip = (chart: any) => {
  let tooltipEl: any = chart.canvas.parentNode.querySelector('.tooltip');

  if (tooltipEl) {
    const { xAlign, x } = tooltipEl;
    if (xAlign === 'left') {
      tooltipEl.style.right = chart.canvas.width - x + window.pageXOffset + 'px';
    } else {
      tooltipEl.style.left = x + window.pageXOffset + 'px';
    }
  }

  if (tooltipEl) return tooltipEl;

  const { tooltip } = chart;
  const { x, y } = tooltip;

  tooltipEl = document.createElement('div');
  tooltipEl.className = 'tooltip';

  // Position tooltip element
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left = x + window.pageXOffset + 'px';

  // Adjust the y position of the tooltip based on yAlign property
  tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
  tooltipEl.style.borderRadius = '3px';
  tooltipEl.style.color = 'white';
  tooltipEl.style.opacity = 1;
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.transform = 'translate(-50%, -100%)';
  tooltipEl.style.transition = 'all 0.3s linear';
  tooltipEl.style.left = x + window.pageXOffset + 'px';
  tooltipEl.style.top = y + window.pageYOffset + 'px';

  const table = document.createElement('table');
  table.style.margin = '0px';

  tooltipEl.appendChild(table);
  chart.canvas.parentNode.appendChild(tooltipEl);

  return tooltipEl;
};

export const externalTooltipHandler = (context: any) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b: any) => b.lines);

    //let monthName = bodyLines[0][0].split(" ")[0];

    let customeBodyNameArr = bodyLines[0][0].split(' ');
    customeBodyNameArr.shift();

    let customeBodyName = customeBodyNameArr.join(' ');

    const tableHead = document.createElement('thead');

    titleLines.forEach((title: any) => {
      title = title;
      const tr: any = document.createElement('tr');
      tr.style.borderWidth = 0;

      const th: any = document.createElement('th');
      th.style.borderWidth = 0;
      th.style.fontSize = '14px';
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body: any, i: any) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement('span');
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = '2px';
      span.style.marginRight = '10px';
      span.style.height = '10px';
      span.style.width = '10px';
      span.style.display = 'inline-block';

      const tr: any = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = 0;

      const td: any = document.createElement('td');
      td.style.borderWidth = 0;
      td.style.fontSize = '12px';
      const text = document.createTextNode(customeBodyName);

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot?.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot?.appendChild(tableHead);
    tableRoot?.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = '2px';
};

export const getTagManagerScript = (code: any) => {
  return (
    `
  <script src="https://p.rankworks.com/tag.js?code=` +
    (code || '') +
    `" async></script>
  `
  );
};

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const timeStampToDateFormat = (t: string) => {
  const formatDate = (timestamp: any) => {
    const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    return date.toLocaleDateString('en-US', options);
  };

  const formattedDate = formatDate(Number(t));
  return formattedDate;
};

const compare = (a: any, b: any) => {
  const amountA = parseInt(a.prices.find((price: any) => price.recurring.interval === 'month').unit_amount);
  const amountB = parseInt(b.prices.find((price: any) => price.recurring.interval === 'month').unit_amount);

  if (amountA < amountB) {
    return -1;
  }
  if (amountA > amountB) {
    return 1;
  }
  return 0;
};

export const modifiedPlanList = (list: any) => {
  let modifiedGmbList: any = [];
  let initialPriceObject: any = {};
  list?.data.forEach((element: any) => {
    const planObj = {
      id: element.id,
      Icon: null,
      title: element.name,
      prices: element.prices.data,
      description: element.description,
      features: element.marketing_features.map((feature: any) => feature.name),
      images: element.images,
      isSubscribed: element.is_activated,
      isUpgradable: false,
    };
    modifiedGmbList.push(planObj);
    const initialPrice: any = element.prices.data.find((price: any) => price.recurring.interval === 'month');
    initialPriceObject[element.name] = initialPrice;
  });
  modifiedGmbList.sort(compare);

  let isFoundUpgradablePosition = false;
  let isNewSubscriber = true;
  for (let index = 0; index < modifiedGmbList.length; index++) {
    const item = modifiedGmbList[index];
    if (isFoundUpgradablePosition === true) modifiedGmbList[index]['isUpgradable'] = true;
    if (item.isSubscribed === true) {
      isFoundUpgradablePosition = true;
      isNewSubscriber = false;
    }
  }

  return [modifiedGmbList, isNewSubscriber, initialPriceObject];
};

export const getMetaConversionsInfo = (obj: any, type: string) => {
  let leads =
    (obj?.data[0]?.actions && obj?.data[0]?.actions.find((action: any) => action.action_type === 'lead')?.value) || 0;

  // Convert string value to number if necessary
  leads = parseInt(leads, 10);
  // Calculate cost per conversion
  const costPerConversion = leads > 0 ? (parseInt(obj?.data[0]?.spend) / leads).toFixed(2) : 0;

  // Calculate conversion rate
  const conversionRate =
    parseInt(obj?.data[0]?.clicks) > 0 ? ((leads / parseInt(obj?.data[0]?.clicks)) * 100).toFixed(2) : 0;

  // Return based on type
  switch (type) {
    case 'cpc':
      return costPerConversion;
    case 'cr':
      return conversionRate;
    default:
      return leads;
  }
};

export const getUserOrg = (permissions: any) => {
  let userOrg: any = null;
  permissions &&
    permissions.forEach((element: any) => {
      if (element.hasOwnProperty('organization_details')) {
        userOrg = element;
      }
    });
  return userOrg;
};

export const getExternalOrgAccounts = (permissions: any) => {
  let externalAccoutList: any = [];
  permissions &&
    permissions.forEach((element: any) => {
      if (!element.hasOwnProperty('organization_details')) {
        externalAccoutList.push(element);
      }
    });
  return externalAccoutList;
};

export const truncateString = (str: any, maxLength: any) => {
  if (!str) return ''; // Handle undefined or null values
  return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
};

export const processSelectedAccount = (response: any) => {
  const userOrg: any = getUserOrg(response?.permissions);
  const externalAccounts: any = getExternalOrgAccounts(response?.permissions);
  const selectedAccountId = response?.working_account.split('_')[1] || '';
  let selectedAccountFilter = [];
  if (selectedAccountId && userOrg && externalAccounts) {
    selectedAccountFilter = userOrg?.organization_details?.accounts.filter(
      (item: any) => item._id === selectedAccountId
    );
    if (selectedAccountFilter.length === 0)
      selectedAccountFilter = externalAccounts.filter(
        (itemEx: any) => itemEx?.account_details._id === selectedAccountId
      );
  }
  let acc =
    selectedAccountFilter.length === 0
      ? userOrg?.organization_details?.accounts[0] || externalAccounts[0]
      : selectedAccountFilter[0];

  return acc;
};

export const getIconButton = (type: string) => {
  if (type === 'facebook') return socialIcon;
  if (type === 'instagram') return igIcon;
  if (type === 'gmb') return googleIcon;
  return;
};

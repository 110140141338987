import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/button';
import services from '../../services';
import { getUserInfo, setSelectedAccountAction, setUserInfoAction } from '../../store/ducks/inSiteAppState';
import { getUserOrg, processSelectedAccount } from '../../utils';
import { TextBlock } from '../../components/text-block';
import Creatable from 'react-select/creatable';
import { Radio } from '../../components/radio';
import { TextField } from '../../components/text-field';
import { Label } from '../../components/label';
import cls from './business-registration.module.scss';
import { Frame } from '../../components/frame';
import { useNavigate } from 'react-router-dom';
import { categories } from '../../constants';
import useDebounceValidation from '../../hooks/useDebounceValidation/useDebounceValidation';
import ReactGoogleAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { Section } from '../../pages/get-help/ui/components/section';
import { PageWrapper } from '../page-wrapper';
import loadingAnimation from '../../assets/lt.json';
import Lottie from 'lottie-react';

interface OptionType {
  value: string;
  label: string;
}

const BusinessRegistrationModal = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState('e-commerce');
  const [companyName, setCompanyName] = useState<string>('');
  const [webUrl, setWebUrl] = useState<string>('');
  const [industryType, setIndustryType] = useState<OptionType | null>(null);
  const [serviceArea, setServiceArea] = useState<any | null>(null);
  const [isValidURL, setIsValidURL] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedServiceArea, setSelectedServiceArea] = useState<any | null>(null);

  let { debouncedServiceCall } = useDebounceValidation({
    setFn: setWebUrl,
  });

  let industryTypeList: any = [];
  categories.forEach((element: string) => {
    const item = {
      value: element,
      label: element,
    };
    industryTypeList.push(item);
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleIndustryTypeChange = (selectedOption: OptionType | null) => {
    setIndustryType(selectedOption);
  };

  const handleWebUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedServiceCall(event.target.value);
  };

  const checkWebUrl = async (url: string) => {
    try {
      await services.checkWebUrlValidity(url);
      setIsValidURL(true);
      return Promise.resolve();
    } catch (error: any) {
      setIsValidURL(false);
      return Promise.reject();
    }
  };

  useEffect(() => {
    if (webUrl) checkWebUrl(webUrl);
  }, [webUrl]);

  const handleServiceArea = async (obj: any) => {
    setServiceArea(obj);
    geocodeByPlaceId(obj.value.place_id)
      .then((results) => {
        const address = {
          line1: '',
          line2: '',
          city: '',
          state: '',
          country: '',
          country_alpha2: '',
          postal_code: '',
          coordinates: {
            lat: '',
            lng: '',
          },
        };
        results[0].address_components.forEach((component: any) => {
          const types = component.types;
          if (types.includes('street_number')) {
            address.line1 = component.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '') + ' ' + address.line1;
          }

          if (types.includes('route')) {
            address.line1 += component.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          }

          if (types.includes('subpremise')) {
            address.line2 = component.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          }

          if (types.includes('locality')) {
            address.city = component.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          }

          if (types.includes('administrative_area_level_1')) {
            address.state = component.short_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          }

          if (types.includes('country')) {
            address.country = component.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            address.country_alpha2 = component.short_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          }

          if (types.includes('postal_code')) {
            address.postal_code = component.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          }
        });

        const latLng = results[0].geometry.location;
        address.coordinates.lat = latLng.lat().toString() || '';
        address.coordinates.lng = latLng.lng().toString() || '';
        setSelectedServiceArea(address);
      })
      .catch((error: any) => console.error(error));
  };

  const handleCreateAccount = async () => {
    try {
      setIsLoading(true);
      let modifiedWebUrl = webUrl;
      if (webUrl && !webUrl.startsWith('http://') && !webUrl.startsWith('https://'))
        modifiedWebUrl = 'https://' + webUrl;
      let data = {
        org_id: 'org_',
        primary_category: industryType?.value || '',
        name: companyName,
        is_ecommerce: value === 'e-commerce' ? true : false,
        websites: webUrl ? [modifiedWebUrl] : [],
        locations: [
          {
            address: selectedServiceArea,
            display_name:
              selectedServiceArea?.line1 + ' ' + selectedServiceArea?.city ||
              '' + selectedServiceArea?.state ||
              '' + selectedServiceArea?.country ||
              '',
            phone: {
              number: '',
              ext: '',
            },
          },
        ],
      };
      await services.createOrgAccount(data);
      const res = await services.getAccountInfo();
      const selectedAccount = processSelectedAccount(res);
      dispatch(setUserInfoAction(res));
      dispatch(setSelectedAccountAction(selectedAccount));
      const userOrg: any = getUserOrg(res?.permissions);
      setIsLoading(false);
      if (!userOrg || userOrg?.organization_details?.plans.length === 0) {
        navigate('/business/plans');
      } else {
        navigate('/');
      }
      return Promise.resolve();
    } catch (e: any) {
      setErrorMessage(e?.response?.data?.detail || 'An error occured!');
      setIsLoading(false);
      return Promise.reject(e);
    }
  };

  return (
    <PageWrapper>
      <div className={cls.BusinessRegistration__wrapper}>
        <div className={cls.BusinessRegistration__wrapper_form}>
          {!loading && (
            <Section className={cls.form_section}>
              <Frame>
                <TextBlock header="Tell us about your business" />
                <Radio
                  className={cls.radioButtonGap}
                  value="e-commerce"
                  name="group"
                  onChange={handleChange}
                  checked={value === 'e-commerce'}
                >
                  eCommence
                </Radio>
                <Radio
                  className={cls.radioGap}
                  value="other"
                  name="group"
                  onChange={handleChange}
                  checked={value === 'other'}
                >
                  Service Website
                </Radio>
                <Label>
                  Company name *
                  <TextField value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                </Label>
                <Label>
                  Website url *
                  <TextField
                    iconSuccess={isValidURL}
                    iconError={webUrl !== '' && !isValidURL}
                    onChange={handleWebUrlChange}
                  />
                </Label>
                <Label>
                  Industry type
                  <Creatable
                    createOptionPosition="first"
                    allowCreateWhileLoading={true}
                    className="industry_type"
                    classNamePrefix="industry_type-select"
                    isSearchable={true}
                    isClearable={true}
                    name="industry_type"
                    options={industryTypeList}
                    value={industryType}
                    onChange={handleIndustryTypeChange}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: '#00003c',
                        height: '40px',
                        '&:hover': {
                          borderColor: '#00003c',
                        },
                      }),
                    }}
                  />
                </Label>
                <Label>
                  Service Area
                  <ReactGoogleAutocomplete
                    apiKey="AIzaSyC5a2Ldhg_3HzR8qVGC_gvOcirZ_RIMQ3c"
                    selectProps={{
                      value: serviceArea,
                      onChange: handleServiceArea,
                    }}
                    apiOptions={{
                      language: 'en', // Specify the language code here (e.g., 'fr' for French)
                      //region: 'ca',
                    }}
                  />
                </Label>
                {errorMessage && (
                  <TextBlock className={cls.errorMessage} sizeS header={errorMessage} errorMessage={true} />
                )}
              </Frame>

              <div className={cls.skipNextBlock}>
                <Button
                  disabled={!companyName || loading || !webUrl || !isValidURL}
                  primary
                  onClick={handleCreateAccount}
                >
                  Next
                </Button>
              </div>
            </Section>
          )}
          {loading && (
            <Section className={cls.form_section}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  height: '100%',
                }}
              >
                <div style={{ display: 'flex' }}>
                  <span
                    style={{
                      display: 'flex',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      backgroundColor: '#93bd7c',
                      borderRadius: '30px',
                      padding: '10px',
                      color: 'white',
                      fontSize: '10px',
                      fontWeight: 800,
                    }}
                  >
                    JUST A MOMENT
                  </span>
                </div>
                <div style={{ display: 'flex' }}>
                  <h1 style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}>
                    Your account is on the way
                  </h1>
                </div>
                <div style={{ display: 'flex' }}>
                  <span
                    style={{
                      display: 'flex',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      color: 'gray',
                      marginBottom: '10px',
                    }}
                  >
                    This may take a few moments, but it'll be worth it!
                  </span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Lottie
                    animationData={loadingAnimation}
                    loop={true}
                    autoplay={true}
                    style={{ width: 150, height: 150 }}
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <h4 style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto', fontSize: 'small' }}>
                    your account is being created...
                  </h4>
                </div>
              </div>
            </Section>
          )}
        </div>
      </div>
    </PageWrapper>
  );
});

export { BusinessRegistrationModal };
